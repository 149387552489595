<template>
	<div class="bodys">
		<ul class="left">
			<li v-for="(item,index) in muntdata" @click="muntdatalistclick(index)"
				:class="[showindex == index ? 'click' : '']">
				{{item}}
			</li>
			<!-- <li class="center" @click="dialogshow = true">
				<i class="el-icon-plus"></i>
			</li> -->
		</ul>
		<div class="right">
			<div v-if="showindex == 0" class="datasxs">
				<div class="list">
					<el-tag class="tages" v-for="(item,index) in queryTraindata" :key="'v'+index" closable
						@close="tagclose(item.id)">
						{{item.equipmentName}}
						<i @click="dialogshow = true,inputvalue = item.equipmentName,queryTrainid = item.id"
							class="el-icon-edit"></i>
					</el-tag>
				</div>
				<div class="add">
					<span @click="dialogshow = true,queryTrainid = 0,inputvalue = ''"><i
							class="el-icon-plus"></i></span>
				</div>
			</div>
			<div v-if="showindex == 1" class="datasxs">
				<div class="selects">
					<el-select @change="selectchange" v-model="firstclassification" placeholder="请选择">
					    <el-option
					      v-for="item in queryTraindata"
					      :key="item.id"
					      :label="item.equipmentName"
					      :value="item.id">
					    </el-option>
					  </el-select>
				</div>
				<div class="list">
					<el-tag class="tages" v-for="(item,index) in trainingtypedata" :key="'v'+index" closable
						@close="tagclose(item.id)">
						{{item.nextTypeName}}
						<i @click="dialogshow = true,inputvalue = item.nextTypeName,queryTrainid = item.id"
							class="el-icon-edit"></i>
					</el-tag>
				</div>
				<div class="add">
					<span @click="dialogshow = true,queryTrainid = 0,inputvalue = ''"><i
							class="el-icon-plus"></i></span>
				</div>
			</div>
			<div v-if="showindex == 2" class="datasxs">
				<div class="list">
					<el-tabs v-model="activeName1" tab-position="left" @tab-click="tabpaneclick">
						<el-tab-pane label="顶面装修规格" name="1">
							<el-tabs @tab-click="tabpaneclick" type="border-card" v-model="activeName2">
								<el-tab-pane label="简装" name="1-1">
									<template v-if="trimStyledata">
										<el-button type="primary">文字介绍</el-button>
										<div class="text">{{trimStyledata.renContent}}</div>
										<el-button type="primary">图片介绍</el-button>
										<div class="img">
											<viewer :images="lxUrl">
												<span :key="'s'+index"
													v-for="(item,index) in trimStyledata.renImage.split(',')"><img
														:src="item" alt=""></span>
											</viewer>
										</div>
										<div class="renPrice">{{trimStyledata.renPrice}}元</div>
									</template>
									<div class="button">
										<el-button v-if="!trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2}})">
											添加
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2,id:trimStyledata.id}})">
											修改
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="deleteTrimStyle(trimStyledata.id)">
											删除
										</el-button>
									</div>
								</el-tab-pane>
								<el-tab-pane label="中装" name="1-2">
									<template v-if="trimStyledata">
										<el-button type="primary">文字介绍</el-button>
										<div class="text">{{trimStyledata.renContent}}</div>
										<el-button type="primary">图片介绍</el-button>
										<div class="img">
											<viewer :images="lxUrl">
												<span :key="'s'+index"
													v-for="(item,index) in trimStyledata.renImage.split(',')"><img
														:src="item" alt=""></span>
											</viewer>
										</div>
										<div class="renPrice">{{trimStyledata.renPrice}}元</div>
									</template>
									<div class="button">
										<el-button v-if="!trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2}})">
											添加
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2,id:trimStyledata.id}})">
											修改
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="deleteTrimStyle(trimStyledata.id)">
											删除
										</el-button>
									</div>
								</el-tab-pane>
								<el-tab-pane label="精装" name="1-3">
									<template v-if="trimStyledata">
										<el-button type="primary">文字介绍</el-button>
										<div class="text">{{trimStyledata.renContent}}</div>
										<el-button type="primary">图片介绍</el-button>
										<div class="img">
											<viewer :images="lxUrl">
												<span :key="'s'+index"
													v-for="(item,index) in trimStyledata.renImage.split(',')"><img
														:src="item" alt=""></span>
											</viewer>
										</div>
										<div class="renPrice">{{trimStyledata.renPrice}}元</div>
									</template>
									<div class="button">
										<el-button v-if="!trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2}})">
											添加
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2,id:trimStyledata.id}})">
											修改
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="deleteTrimStyle(trimStyledata.id)">
											删除
										</el-button>
									</div>
								</el-tab-pane>
							</el-tabs>
						</el-tab-pane>
						<el-tab-pane label="地面装修规格" name="2">
							<el-tabs @tab-click="tabpaneclick" type="border-card" v-model="activeName2">
								<el-tab-pane label="简装" name="2-1">
									<template v-if="trimStyledata">
										<el-button type="primary">文字介绍</el-button>
										<div class="text">{{trimStyledata.renContent}}</div>
										<el-button type="primary">图片介绍</el-button>
										<div class="img">
											<viewer :images="lxUrl">
												<span :key="'s'+index"
													v-for="(item,index) in trimStyledata.renImage.split(',')"><img
														:src="item" alt=""></span>
											</viewer>
										</div>
										<div class="renPrice">{{trimStyledata.renPrice}}元</div>
									</template>
									<div class="button">
										<el-button v-if="!trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2}})">
											添加
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2,id:trimStyledata.id}})">
											修改
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="deleteTrimStyle(trimStyledata.id)">
											删除
										</el-button>
									</div>
								</el-tab-pane>
								<el-tab-pane label="中装" name="2-2">
									<template v-if="trimStyledata">
										<el-button type="primary">文字介绍</el-button>
										<div class="text">{{trimStyledata.renContent}}</div>
										<el-button type="primary">图片介绍</el-button>
										<div class="img">
											<viewer :images="lxUrl">
												<span :key="'s'+index"
													v-for="(item,index) in trimStyledata.renImage.split(',')"><img
														:src="item" alt=""></span>
											</viewer>
										</div>
										<div class="renPrice">{{trimStyledata.renPrice}}元</div>
									</template>
									<div class="button">
										<el-button v-if="!trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2}})">
											添加
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2,id:trimStyledata.id}})">
											修改
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="deleteTrimStyle(trimStyledata.id)">
											删除
										</el-button>
									</div>
								</el-tab-pane>
								<el-tab-pane label="精装" name="2-3">
									<template v-if="trimStyledata">
										<el-button type="primary">文字介绍</el-button>
										<div class="text">{{trimStyledata.renContent}}</div>
										<el-button type="primary">图片介绍</el-button>
										<div class="img">
											<viewer :images="lxUrl">
												<span :key="'s'+index"
													v-for="(item,index) in trimStyledata.renImage.split(',')"><img
														:src="item" alt=""></span>
											</viewer>
										</div>
										<div class="renPrice">{{trimStyledata.renPrice}}元</div>
									</template>
									<div class="button">
										<el-button v-if="!trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2}})">
											添加
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="$router.push({ path: 'datamodify',query:{type:activeName2,id:trimStyledata.id}})">
											修改
										</el-button>
										<el-button v-if="trimStyledata" type="primary" plain
											@click="deleteTrimStyle(trimStyledata.id)">
											删除
										</el-button>
									</div>
								</el-tab-pane>
							</el-tabs>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="dialogshow" width="40%" class="addform">
			<template>
				<el-input v-model="inputvalue"></el-input>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onSubmit">提交</el-button>
				<el-button @click="dialogshow = false">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				queryTrainid: 0,
				inputvalue: '',
				activeName1: '1',
				activeName2: '1-1',
				lxUrl: [],
				dialogshow: false,
				muntdata: ['设备类型', '设备里实训室类型', '装修规格'],
				tagdatas: ['钢琴实训室', '钢琴实训室'],
				showindex: 0,
				queryTraindata: [],
				trainingtypedata: [],
				trimStyledata: '',
				firstclassification:''
			}
		},
		created() {
			this.queryTrainType()
			if (sessionStorage.getItem('datamanagementindex')) {
				this.showindex = sessionStorage.getItem('datamanagementindex')
			} else {
				sessionStorage.setItem('datamanagementindex', 0)
			}
			this.$nextTick(()=>{
				if (this.$route.query.type) {
					// console.log("123456")
					this.activeName1 = this.$commonFun.getBeforStr(this.$route.query.type, '-')
					this.activeName2 = this.activeName1 + '-' + this.$commonFun.getAfterStr(this.$route.query.type, '-')
					this.queryTrimStyle(this.$commonFun.getBeforStr(this.$route.query.type, '-'), this.$commonFun.getAfterStr(
						this.$route.query.type, '-'))
				} else {
					if (this.showindex == 2) {
						this.queryTrimStyle(1, 1)
					}
					if (this.showindex == 0) {
						this.queryTrainType()
					}
					if (this.showindex == 1) {
						console.log("2255")
						// this.queryTrainType()
						// this.gettrainingtype(this.firstclassification)
					}
				}
			})
			
		},
		mounted() {

		},
		methods: {
			selectchange(val){
				this.gettrainingtype(val)
			},
			//获取二级分类
			async gettrainingtype(id) {
				console.log(id)
				await this.$post('equipment/queryEquipmentNextType',{
					proId:id
				}).then((res) => {
					// console.log(trainingtypedata)
					console.log(res)
					this.trainingtypedata = res
				})
			},
			//提交设备类型添加修改
			async onSubmit() {
				if(this.showindex == 0){
					let data = {
						shixunName: this.inputvalue
					}
					data.id = this.queryTrainid
					await this.$post('equipment/addEquipmentType', data).then((res) => {
						this.$message({
							type: 'success',
							message: this.queryTrainid == 0 ? '添加成功!' : '修改成功'
						});
						this.dialogshow = false
					})
					this.queryTrainType()
				}
				if(this.showindex == 1){
					let data = {
						proId:this.firstclassification,
						nextTypeName: this.inputvalue
					}
					data.nextTypeId = this.queryTrainid
					await this.$post('equipment/addEquipmentNextType', data).then((res) => {
						this.$message({
							type: 'success',
							message: this.queryTrainid == 0 ? '添加成功!' : '修改成功'
						});
						this.dialogshow = false
					})
					this.gettrainingtype(this.firstclassification)
				}
				

			},
			//获取全部的设备类型
			queryTrainType() {
				this.$post('equipment/getEquipmentTypeList').then((res) => {
					this.queryTraindata = res
					this.firstclassification = res[0].id
					this.gettrainingtype(res[0].id)
				})
			},
			//设备类型删除
			tagclose(id) {
				this.$confirm('此操作将删除该类型, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {
					if(this.showindex == 0){
						this.$post('equipment/deleteEquipmentType', {
							id: id
						}).then((res) => {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.queryTrainType()
						})
					}
					if(this.showindex == 1){
						this.$post('equipment/delEquipmentNextType', {
							nextTypeId: id
						}).then((res) => {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.gettrainingtype(this.firstclassification)
						})
					}
					
				}).catch(() => {
				
				});
			},
			//装修风格点击tab栏
			tabpaneclick(val) {
				this.$router.push({
					query: {}
				})
				if (val.name.indexOf('-') == -1) {
					this.activeName2 = this.activeName1 + '-' + '1'
				}
				let q = this.$commonFun.getBeforStr(this.activeName2, '-')
				let e = this.$commonFun.getAfterStr(this.activeName2, '-')
				this.queryTrimStyle(q, e)
			},
			//获取装修风格数据
			queryTrimStyle(q, w) {
				this.lxUrl = []
				this.trimStyledata = ""
				this.$post('queryTrimStyle', {
					typeId: q,
					styleId: w
				}).then((res) => {
					this.trimStyledata = res
					let asd = res.renImage.split(',')
					asd.forEach((e, m) => {
						this.lxUrl.push(this.$commonFun.imgserverSrc + e)
					})
					console.log(this.lxUrl)
				})
			},
			//删除装修数据
			deleteTrimStyle(id) {
				console.log(this.activeName1)
				console.log(this.activeName2)
				this.$confirm('此操作将删除数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {
					this.$post('deleteTrimStyleById', {
						id: id
					}).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						let q = this.$commonFun.getBeforStr(this.activeName2, '-')
						let e = this.$commonFun.getAfterStr(this.activeName2, '-')
						this.queryTrimStyle(q, e)
					})
				}).catch(() => {});
			},
			//右边导航栏点击时
			muntdatalistclick(index) {
				sessionStorage.setItem('datamanagementindex', index)
				this.showindex = index
				this.activeName1 = '1'
				this.activeName2 = '1-1'
				index == 0 ? this.queryTrainType():index == 1 ? this.gettrainingtype(this.firstclassification) : this.queryTrimStyle(1, 1)
			}
		}
	}
</script>

<style scoped lang="less">
	.bodys {
		.navCommon {
			padding: 20px 0;
			margin-bottom: 20px;
		}

		.left,
		.right {
			display: inline-block;
			vertical-align: top;
		}

		.left {
			width: 14%;
			margin-right: 2%;
			border-bottom: 1px solid #eee;

			li {
				padding: 15px;
				font-size: 16px;
				box-sizing: border-box;
				cursor: pointer;
				border: 1px solid #eee;
				border-bottom: none;
			}

			li.center {
				text-align: center;
			}

			li.click {
				border-right: none;
			}
		}

		.right {
			width: 84%;

			.datasxs {
				>div {
					width: 50%;
				}

				.list {
					width: 100%;

					.tages {
						margin-right: 30px;
						margin-bottom: 30px;
						font-size: 16px;
					}

					i.el-icon-edit {
						cursor: pointer;
						margin: 0 15px;
					}

					.text {
						margin: 20px;
						line-height: 20px;
						font-size: 16px;
					}

					.img {
						margin: 20px;

						span {
							font-size: 0;
							display: inline-block;
							vertical-align: top;
							width: 18.4%;
							margin-right: 2%;
							margin-bottom: 2%;
							max-height: 132px;
							overflow: hidden;

							>img {
								width: 100%;
							}
						}

						span:nth-child(5) {
							margin-right: 0;
						}
					}

					.button {
						text-align: center;
						margin-top: 80px;
					}
				}

				.add {
					margin-top: 80px;
					text-align: center;

					>span {
						cursor: pointer;
						padding: 20px;
						border: 1px dashed #cbcbcb;
						line-height: 20px;
					}
				}
			}
		}

		.renPrice {
			font-size: 20px;
			color: #61AEEE;
		}
		.selects{
			margin: 0 0 30px 0;
		}
	}
</style>
